import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO title="404: Not found" location={location} />
    <div className="content page" center>
      <h1>Oops! Page not found</h1>
    </div>
  </Layout>
)

export default NotFoundPage
